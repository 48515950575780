import styled from 'styled-components'

import { rhythm } from '../../../utils/typography'

export const Details = styled.details`
  border-radius: 10px;
  border: 1px solid var(--primary);
  padding: ${rhythm(.3)};

  p {
    margin-top: ${rhythm(1)};
  }
`
