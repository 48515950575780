import React, { FormEvent, ReactElement } from 'react'
import styled from 'styled-components'

import selectIcon from '../../../static/svg/align-center.svg'

import { IProduct } from '../../@types'
import { updateCart } from '../../functions/updateCart'
import { inputStyles } from '../../globalStyles'
import { rhythm } from '../../utils/typography'
import { CartUpdates } from '../../vars'

interface IProps {
  currentQuantity?: number
  className?: string
  id?: string
  product: IProduct

  onQuantityChange?(newQuantity?: number): void
}

export function QuantitySelect({
  onQuantityChange,
  currentQuantity = 1,
  className,
  id,
  product,
}: IProps) {
  function quantityOptions(amount: number): ReactElement[] {
    const emptyArray: string[] = new Array(amount).fill('')

    return emptyArray.map((_emptySpot, index) => {
      const value = index + 1

      return (
        <option key={index} value={value}>
          {value}
        </option>
      )
    })
  }

  function handleQuantityChange(e: FormEvent<HTMLSelectElement>) {
    const { text } = e.currentTarget.selectedOptions[0]

    const newQuantity = text ? Number.parseInt(text, 10) : 0

    updateCart(product, CartUpdates.QUANTITY, newQuantity)

    if (onQuantityChange) {
      onQuantityChange(newQuantity)
    }
  }

  return (
    <Select
      defaultValue={currentQuantity.toString()}
      name='quantity'
      id={id}
      onChange={handleQuantityChange}
      className={className}
      aria-label='quantity'
      title='quantity'
    >
      {quantityOptions(25)}
    </Select>
  )
}

const Select = styled.select`
  ${inputStyles}
  width: 3rem;
  height: max-content;
  margin: ${rhythm(1 / 2)};
  padding: ${rhythm(1 / 4)};
  display: block;
  background: var(--white) url(${selectIcon}) no-repeat right;
  background-size: 1.4rem auto;
  appearance: none;
  cursor: pointer;

  ::-ms-expand {
    display: none;
  }
`
