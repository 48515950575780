import styled, { css, keyframes } from 'styled-components'

import { ButtonLink, cardEnterAnim, IconButton } from '../../../../globalStyles'
import { rhythm } from '../../../../utils/typography'
import { mediaQueries } from '../../../../vars'
import { Image } from '../../../common/Image'
import { popupContainerStyles } from '../styles'

export const Container = styled.div`
  ${popupContainerStyles};
  background: #ffffff;

  > section {
    width: 700px;
    max-width: 100%;
    justify-self: center;
  }

  @media (min-width: 480px) {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: ${rhythm(1)};
  }
`

export const ImageSection = styled.section`
  max-height: calc(100vh - 2 * ${rhythm(1)});
  display: flex;
  align-items: center;

  @media (min-width: 480px) {
    position: sticky;
    top: 0;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: 0 auto ${rhythm(1)};
  max-width: 100%;
`

interface IAspectRatio {
  aspectRatio: number
}

export const ItemImage = styled(Image)<IAspectRatio>`
  --max-height: 50vh;

  @media (min-width: 480px) {
    --max-height: 80vh;
  }

  width: ${({ aspectRatio }) =>
  aspectRatio ? `calc(var(--max-height) * ${aspectRatio})` : '20vw'};
  max-width: 100%;
  max-height: var(--max-height);
  z-index: -1;
  border-radius: 5px;
`

export const Info = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: var(--white);
  background: #00000066;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: ${rhythm(0.7)};
  transition: opacity 0.7s;
  border-radius: 5px;
  display: flex;

  :hover {
    opacity: 0;
  }

  font-size: 1.1rem;

  @media (max-height: 600px) and (orientation: portrait),
    (max-width: 600px) and (orientation: landscape) {
    font-size: 0.9rem;
    padding: ${rhythm(1 / 2)};
  }

  @media (min-height: 700px) and (orientation: portrait),
    (min-width: 700px) and (orientation: landscape) {
    font-size: 1.4rem;
  }
`

export const Description = styled.div`
  h2 {
    font-size: 1em;
    line-height: 1.1em;
    margin-bottom: 0.5em;
  }

  p {
    color: #eee;
    font-size: 0.9em;
    line-height: 1em;
  }
`

export const Price = styled.span`
  font-size: 2.3rem;
  line-height: 1.8rem;
  margin-left: auto;
  align-self: flex-end;
  display: flex;

  span {
    font-size: 1.3rem;
    line-height: 1.3rem;
    align-self: flex-start;
  }

  @media ${mediaQueries.portrait.handheld}, ${mediaQueries.landscape.handheld} {
    font-size: 1.7rem;
    line-height: 1.2rem;

    span {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
`

export const Sidebar = styled.section`
  align-self: center;
  justify-content: center;
  width: 100%;
`

export const Inputs = styled.section`
  margin: 0;
  width: 100%;
  height: fit-content;

  h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: ${rhythm(1.2)};
  }

  @media ${mediaQueries.landscape.tablet},
    ${mediaQueries.portrait.tablet},
    ${mediaQueries.landscape.desktop},
    ${mediaQueries.portrait.desktop} {
    padding: ${rhythm(1)};
    border-radius: 10px;
    background: #ffffffdd;
    box-shadow: var(--box-shadow-very-light);

    h1 {
      font-size: 1.7rem;
      line-height: 2rem;
    }
  }
`

export const Fieldset = styled.fieldset`
  margin-bottom: ${rhythm(1.2)};
`

export const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
`

export const Legend = styled.legend`
  color: #333;
  font-size: 1rem;
`

export const Actions = styled.section`
  margin: ${rhythm(1)} 0;
  width: 100%;
  height: fit-content;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  padding: ${rhythm(1 / 2)};
  background: #ffffffdd;
  border-radius: 10px;
  box-shadow: var(--box-shadow-very-light);
  display: flex;
  justify-content: space-around;
`

export const AddedToCartSection = styled.section`
  animation: ${cardEnterAnim} 0.6s 0.2s backwards;
  position: relative;
  background: #ffffffdd;
  padding: ${rhythm(1)};
  border-radius: 10px;
  box-shadow: var(--box-shadow-very-light);
  grid-area: 1 / 1 / 3 / 3;
  max-height: 400px;
  align-self: center;
  display: grid;
  grid:
    'Header Header' 1.5fr
    'Buttons Buttons' 1fr
    'ToCheckout ToCheckout' 1fr
    / 1fr 1fr;
  gap: ${rhythm(1)};

  h1 {
    grid-area: Header;
    font-size: 2rem;

    @media ${mediaQueries.general.handheld} {
      font-size: 1.3rem;
      line-height: 1.9rem;
      margin-bottom: 0;
    }
  }
`

const buttonStyles = css`
  color: var(--white);
  box-shadow: var(--box-shadow-light);
  background-color: #00000077;
  font-weight: bold;
  width: 100%;
  padding: ${rhythm(1 / 4)};
  border-radius: 5px;
  border: 1px solid var(--primary);
  justify-content: center;
  align-items: center;

  img {
    height: 1.4rem;
    margin-left: 0.2rem;
  }
`

export const Link = styled(ButtonLink)`
  ${buttonStyles}
`

export const Button = styled(IconButton)`
  ${buttonStyles}
`

const buttonHover = keyframes`
  from {
    background-position: 0;
  }
  to {
    background-position: 4rem;
  }
`

export const ToCheckoutLink = styled(Link)`
  grid-area: ToCheckout;
  background: transparent;
  color: var(--black);
  font-weight: bold;
  :hover {
    background-image: linear-gradient(
      135deg,
      #bbbbbb11 25%,
      transparent 25%,
      transparent 50%,
      #bbbbbb11 50%,
      #bbbbbb11 75%,
      transparent 75%,
      transparent
    );
    animation: 5s linear 0s infinite normal none running ${buttonHover};
    background-size: 2rem 2rem;
  }

  @media ${mediaQueries.portrait.tablet}, ${mediaQueries.landscape.tablet} {
    font-size: 1.5rem;
  }
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`
