import React, { ReactChild } from 'react'
import styled from 'styled-components'

import checkmark from '../../../static/svg/ok-circle.svg'
import emptyBox from '../../../static/svg/rectangle.svg'

import { inputStyles } from '../../globalStyles'
import { rhythm } from '../../utils/typography'
import { Icon } from './Radios/styles'

interface IProps {
  id: string
  selectedId: string
  radioGroup: string
  changeHandler: () => void
  children: ReactChild | ReactChild[]
  ariaLabelledBy?: string
  className?: string
}

export function RadioInput({
  id,
  selectedId,
  radioGroup,
  changeHandler,
  children,
  className,
  ariaLabelledBy,
}: IProps) {
  const checked = id === selectedId

  return (
    <InputWrapper className={className}>
      <Input
        type='radio'
        id={id}
        value={id}
        name={radioGroup}
        onChange={changeHandler}
        checked={checked}
        aria-labelledby={ariaLabelledBy ? ariaLabelledBy : ''}
      />
      <VariantLabel htmlFor={id}>
        {children}
        <Icon
          src={checked ? checkmark : emptyBox}
          alt={checked ? 'Checkmark icon' : 'Empty box icon'}
        />
      </VariantLabel>
    </InputWrapper>
  )
}

export const InputWrapper = styled.div`
  display: contents;
`

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

export const VariantLabel = styled.label`
  ${inputStyles};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.2s;
  padding: ${rhythm(1 / 3)};
  margin: ${rhythm(1 / 2)} auto;
  opacity: 0.8;

  input:checked ~ & {
    border: 1px solid var(--primary);
    opacity: 1;
  }

  :hover {
    box-shadow: var(--box-shadow);
  }
`
