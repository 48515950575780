import React, { useRef, useState } from 'react'
import { TransitionStatus } from 'react-transition-group/Transition'

import cartIcon from '../../../../../static/svg/cart.svg'
import closeIcon from '../../../../../static/svg/close.svg'
import imageIcon from '../../../../../static/svg/image.svg'
import arrowIcon from '../../../../../static/svg/play.svg'

import { IProduct, ISanityPhoto, IVariant } from '../../../../@types'
import { RadioElements } from '../../../../classes/RadioElements'
import { Variants } from '../../../../classes/Variants'
import { getCurrentCart } from '../../../../functions/getCurrentCart'
import { IconButton } from '../../../../globalStyles'
import { PageURLs } from '../../../../vars'
import { AddToCartButton } from '../../../common/AddToCartButton'
import { QuantitySelect } from '../../../common/QuantitySelect'
import { Icon } from '../styles'
import {
  Actions,
  AddedToCartSection,
  Button,
  CloseButton,
  Container,
  Description,
  Fieldset,
  ImageContainer,
  ImageSection,
  Info,
  Inputs,
  ItemImage,
  Legend,
  Link,
  Price,
  RadioGroup,
  Sidebar,
  ToCheckoutLink,
} from './styles'
import { useModalFocus } from '../../../../hooks/useModalFocus'
import { CustomPrintNotification } from '../../../common/CustomPrintNotification'
import { MaterialLegend } from './MaterialLegend'

interface IProps {
  currentPhoto: ISanityPhoto
  transitionState: TransitionStatus

  closeFunction(): void
}

export function AddToCart({
  currentPhoto,
  closeFunction,
  transitionState,
}: IProps) {
  const [addedToCart, setAddedToCart] = useState(false)

  const cartLengthRef = useRef<number>()

  const focusElRef = useRef<HTMLFieldSetElement | null>(null)

  useModalFocus(focusElRef)

  const { variants, variantsObject } = new Variants(currentPhoto._rawVariants)

  const [selectedVariant, setSelectedVariant] = useState<IVariant>(
    variantsObject[Object.keys(variantsObject)[0]],
  )

  const [quantity, setQuantity] = useState<number>(1)

  const product: IProduct = {
    ...currentPhoto,
    variants,
    selectedVariant,
    quantity,
  }

  const { materialRadios, materialSizeRadios } = new RadioElements(
    variants,
    handleRadioChange,
    selectedVariant.material,
    selectedVariant.size,
  )

  function handleRadioChange(variantTitle: string) {
    setSelectedVariant({
      ...variantsObject[variantTitle],
      quantity,
    })
  }

  function handleQuantityChange(newQuantity: number) {
    setQuantity(newQuantity)
  }

  function handleSuccessfulCartAdd() {
    const currentCart     = getCurrentCart()
    cartLengthRef.current = currentCart ? currentCart.length : undefined

    setAddedToCart(true)
  }

  return (
    <Container>
      {addedToCart ? (
        <AddedToCartSection>
          <h1>{currentPhoto.photoTitle} has been added to your cart</h1>
          <CloseButton
            onClick={closeFunction}
            value='Close'
            title='Close add to cart'
          >
            <Icon src={closeIcon} alt='Close icon' />
          </CloseButton>
          <Button onClick={closeFunction}>
            Back to photos
            <img src={imageIcon} alt='Image icon' />
          </Button>
          <Link to={PageURLs.Cart}>
            Go to cart
            <img src={cartIcon} alt='Cart icon' />
          </Link>
          <ToCheckoutLink to={PageURLs.Checkout}>
            Proceed to checkout{' '}
            {cartLengthRef.current && `(${cartLengthRef.current} items)`}
            <img src={arrowIcon} alt='Arrow circle icon' />
          </ToCheckoutLink>
        </AddedToCartSection>
      ) : (
        <>
          <ImageSection className={`photos ${transitionState}`}>
            <ImageContainer>
              {currentPhoto.photo.asset.fluidLarge && (
                <ItemImage
                  alt={currentPhoto.photoTitle}
                  fluidAsset={currentPhoto.photo.asset.fluidLarge}
                  aspectRatio={currentPhoto.photo.asset.fluidLarge.aspectRatio}
                />
              )}
              <Info>
                <Description>
                  <h2>{currentPhoto.photoTitle}</h2>
                  <p>{selectedVariant.variantTitle}</p>
                </Description>
                <Price>
                  <span>$</span>
                  {selectedVariant.price}
                </Price>
              </Info>
            </ImageContainer>
          </ImageSection>

          <Sidebar className={`content ${transitionState}`}>
            <Inputs>
              <h1>Add "{currentPhoto.photoTitle}" to your cart:</h1>
              <Fieldset tabIndex={0} ref={focusElRef}>
                <MaterialLegend />
                <RadioGroup>{materialRadios}</RadioGroup>
              </Fieldset>

              <Fieldset>
                <Legend>Choose a size</Legend>
                <RadioGroup>
                  {materialSizeRadios[selectedVariant.material].sizeRadios}
                </RadioGroup>
              </Fieldset>

              <Fieldset>
                <Legend>Choose a quantity</Legend>
                <QuantitySelect
                  product={product}
                  onQuantityChange={handleQuantityChange}
                />
              </Fieldset>
              <CustomPrintNotification />
            </Inputs>

            <Actions>
              <AddToCartButton
                product={product}
                onSuccess={handleSuccessfulCartAdd}
              />
              <IconButton
                onClick={closeFunction}
                value='Close'
                title='Close add to cart'
              >
                <Icon src={closeIcon} alt='Close icon' />
              </IconButton>
            </Actions>
          </Sidebar>
        </>
      )}
    </Container>
  )
}
