import { MutableRefObject, useEffect } from 'react'

const documentGlobal: Document | false = typeof document !==
                                         'undefined' &&
                                         document

export function useModalFocus(focusElement: MutableRefObject<HTMLElement | null>) {
  if (!documentGlobal) {
    return
  }

  useEffect(() => {
    const focusedElBeforeMount = documentGlobal.activeElement as HTMLElement

    if (focusElement.current) {
      focusElement.current.focus()
    }

    return () => {
      if (focusedElBeforeMount) {
        focusedElBeforeMount.focus()
      }
    }
  }, [])
}