import React from 'react'

import addToCartIcon from '../../../../static/svg/cart-add.svg'
import maximiseIcon from '../../../../static/svg/maximise.svg'

import { ISanityPhoto, TTogglePhotoPopup } from '../../../@types'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { mediaQueries } from '../../../vars'
import {
  AddToCartOverlayBtn,
  ColumnItemProtector,
  Container,
  Icon,
  MaximiseOverlayBtn,
  StyledImage,
} from './styles'

interface IProps {
  sanityPhoto: ISanityPhoto
  togglePhotoPopup: TTogglePhotoPopup
  photoIndex: number
}

export function Photo({ sanityPhoto, togglePhotoPopup, photoIndex }: IProps) {
  const showButtons = useMediaQuery(mediaQueries.general.desktop)

  function handleAddToCartClick(): void {
    togglePhotoPopup(photoIndex, true)
  }

  function handleMaximiseClick(): void {
    togglePhotoPopup(photoIndex, false)
  }

  return (
    <ColumnItemProtector>
      <Container>
        <div onClick={handleMaximiseClick}>
          <StyledImage
            alt={sanityPhoto.photoTitle}
            fluidAsset={sanityPhoto.photo.asset.fluidSmall}
          />
        </div>

        {showButtons && (
          <>
            <AddToCartOverlayBtn
              onClick={handleAddToCartClick}
              aria-label='Add to cart'
              title='Add to cart'
            >
              <Icon src={addToCartIcon} alt='Add to cart icon' />
            </AddToCartOverlayBtn>

            <MaximiseOverlayBtn
              aria-label='Maximize button'
              onClick={handleMaximiseClick}
              title='Show in gallery'
            >
              <Icon src={maximiseIcon} alt='Maximize icon' />
            </MaximiseOverlayBtn>
          </>
        )}
      </Container>
    </ColumnItemProtector>
  )
}
