import styled, { css, keyframes } from 'styled-components'

import { rhythm } from '../../../utils/typography'

export const animationDuration = 450

export const animDur = `${animationDuration}ms`

export const slideOutRight = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(150vw);
  }
`

export const slideOutLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-150vw);
  }
`

export const popupContainerStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  max-width: 100vw;
  background-color: #eeeeeedd;
  overflow: scroll;
  padding: ${rhythm(1)};

  > .photos,
  > .content {
    animation-duration: ${animDur};
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  > .entering {
    animation-direction: reverse;
  }

  > .exiting.photos {
    animation-name: ${slideOutRight};
  }

  > .exiting.content {
    animation-name: ${slideOutLeft};
  }

  > .entering.photos {
    animation-name: ${slideOutRight};
  }

  > .entering.content {
    animation-name: ${slideOutLeft};
  }
`

export const Icon = styled.img`
  margin: 0;
`
