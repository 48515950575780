import React, { ReactElement } from 'react'
import { IVariant } from '../@types'
import { MaterialRadio, SizeRadio } from '../components/common/Radios'

interface IMaterialSizeRadioPair {
  materialRadio: ReactElement
  sizeRadios: ReactElement[]
}

interface IMaterialSizeRadio {
  [material: string]: IMaterialSizeRadioPair
}

export type tRadioChangeHandler = (variantTitle: string) => void

export class RadioElements {
  public materialSizeRadios: IMaterialSizeRadio

  constructor(
    variantsArray: IVariant[],
    radioChangeHandler: tRadioChangeHandler,
    selectedMaterial: string,
    selectedSize: string
  ) {
    function getMaterialSizePairs(): IMaterialSizeRadio {
      return variantsArray.reduce(
        (accumulatedObject: IMaterialSizeRadio, curVariant: IVariant) => {
          const { material, size, variantTitle } = curVariant

          const materialRadio = (
            <MaterialRadio
              key={material}
              changeHandler={radioChangeHandler}
              materialName={material}
              selectedMaterial={selectedMaterial}
              variantTitle={variantTitle}
            />
          )

          const sizeRadio = (
            <SizeRadio
              key={size}
              changeHandler={radioChangeHandler}
              size={size}
              variantTitle={variantTitle}
              selectedSize={selectedSize}
            />
          )

          accumulatedObject[material] = !accumulatedObject[material]
            ? { materialRadio, sizeRadios: [sizeRadio] }
            : {
                ...accumulatedObject[material],
                sizeRadios: [
                  ...accumulatedObject[material].sizeRadios,
                  sizeRadio,
                ],
              }

          return accumulatedObject
        },
        {}
      )
    }

    this.materialSizeRadios = getMaterialSizePairs()
  }

  get materialRadios(): ReactElement[] {
    return Object.keys(this.materialSizeRadios).map(
      key => this.materialSizeRadios[key].materialRadio
    )
  }
}
