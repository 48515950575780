import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { TransitionStatus } from 'react-transition-group/Transition'

import cartAdd from '../../../../../static/svg/cart-add.svg'
import back from '../../../../../static/svg/chevron-left.svg'
import forward from '../../../../../static/svg/chevron-right.svg'
import close from '../../../../../static/svg/close.svg'
import pauseIcon from '../../../../../static/svg/pause.svg'
import playIcon from '../../../../../static/svg/play.svg'

import { ISanityPhoto } from '../../../../@types'
import { useSwipe } from '../../../../hooks/useSwipe'
import { Icon } from '../styles'
import {
  Button,
  ButtonDiv,
  Buttons,
  CloseButton,
  Container,
  Controls,
  Description,
  Photos,
  PhotoWrapper,
  StyledImage,
  SupportingContent,
  Timer,
} from './styles'
import { useModalFocus } from '../../../../hooks/useModalFocus'

const windowGlobal: Window | false = typeof window !== 'undefined' && window

interface IProps {
  photoIndex: number
  photoArray: ISanityPhoto[]
  transitionState: TransitionStatus

  closeFunction(): void

  handleAddToCartClick(index: number): void
}

export function Gallery({
  photoIndex,
  photoArray,
  transitionState,
  handleAddToCartClick,
  closeFunction,
}: IProps) {
  if (!windowGlobal) {
    return null
  }

  const [currentIndex, setCurrentIndex]         = useState<number>(photoIndex)
  const [playing, setPlaying]                   = useState<boolean>(false)
  const [intervalFraction, setIntervalFraction] = useState<number>(0)

  const currentPhoto = photoArray[currentIndex]

  const photoIntervalRef = useRef<number | NodeJS.Timeout>()
  const timerIntervalRef = useRef<number | NodeJS.Timeout>()
  const currentIndexRef  = useRef<number>(currentIndex)
  const focusElRef       = useRef<HTMLButtonElement | null>(null)

  useModalFocus(focusElRef)

  function play() {
    setPlaying(true)

    const interval = 4000
    let time       = Date.now()

    timerIntervalRef.current = setInterval(() => {
      setIntervalFraction((Date.now() - time) / interval)
    }, 10)

    photoIntervalRef.current = setInterval(() => {
      time = Date.now()
      nextImage()
    }, interval)
  }

  function pause() {
    if (
      typeof photoIntervalRef.current === 'number' &&
      typeof timerIntervalRef.current === 'number'
    ) {
      clearInterval(photoIntervalRef.current)
      clearInterval(timerIntervalRef.current)
    }

    setIntervalFraction(0)
    setPlaying(false)
  }

  function previousImage() {
    const indexMinusOne = currentIndexRef.current - 1

    const newIndex = photoArray[indexMinusOne]
      ? indexMinusOne
      : indexMinusOne < 0
        ? photoArray.length - 1
        : currentIndex

    currentIndexRef.current = newIndex
    setCurrentIndex(newIndex)
  }

  function nextImage() {
    const indexPlusOne = currentIndexRef.current + 1

    const newIndex = photoArray[indexPlusOne]
      ? indexPlusOne
      : indexPlusOne > photoArray.length - 1
        ? 0
        : currentIndex

    currentIndexRef.current = newIndex
    setCurrentIndex(newIndex)
  }

  function onAddToCartClick() {
    pause()
    handleAddToCartClick(currentIndex)
  }

  useEffect(() => {
    return () => {
      if (
        typeof photoIntervalRef.current === 'number' &&
        typeof timerIntervalRef.current === 'number'
      ) {
        clearInterval(photoIntervalRef.current)
        clearInterval(timerIntervalRef.current)
      }
    }
  }, [])

  const touchHandlers = useSwipe(nextImage, previousImage, false)

  return (
    <Container role='dialog'>
      <Photos {...touchHandlers} className={`photos ${transitionState}`}>
        {getPhotos(photoArray, currentIndex, nextImage, previousImage)}
      </Photos>
      <SupportingContent className={`content ${transitionState}`}>
        <Description>
          <h1>{currentPhoto.photoTitle}</h1>
          <p>{currentPhoto.desc}</p>
        </Description>
        <ButtonDiv>
          <Controls>
            <Button
              title='Go to previous image'
              onClick={previousImage}
              ref={focusElRef}
            >
              <Icon src={back} alt='Previous icon' />
            </Button>
            <Button
              title='Start or stop image rotation'
              onClick={playing ? pause : play}
            >
              {playing ? (
                <Icon src={pauseIcon} alt='pause icon' />
              ) : (
                <Icon src={playIcon} alt='Play icon' />
              )}
            </Button>
            <Button title='Go to next image' onClick={nextImage}>
              <Icon src={forward} alt='Next icon' />
            </Button>
          </Controls>
          <Buttons>
            <Button title='Begin adding to cart' onClick={onAddToCartClick}>
              <Icon src={cartAdd} alt='Add to cart icon' />
            </Button>
            <CloseButton
              onClick={closeFunction}
              value='Close'
              title='Close image gallery'
            >
              <Icon src={close} alt='Close icon' />
            </CloseButton>
          </Buttons>
        </ButtonDiv>
      </SupportingContent>
      <Timer intervalFraction={intervalFraction} />
    </Container>
  )
}

function getPhotos(
  photoArray: ISanityPhoto[],
  currentIndex: number,
  nextImage: () => void,
  prevImage: () => void,
): ReactElement[] {
  const photosToLoad         = photoArray.length > 5 ? 5 : 3
  const selectedCurrentIndex = Math.floor((photosToLoad - 1) / 2)

  const firstPhotoIndex = currentIndex - selectedCurrentIndex
  const lastPhotoIndex  = firstPhotoIndex + photosToLoad

  let selectedPhotos = photoArray.slice(firstPhotoIndex, lastPhotoIndex)

  if (selectedPhotos.length !== photosToLoad) {
    if (firstPhotoIndex < 0) {
      const missingItems = photoArray.slice(0, photosToLoad + firstPhotoIndex)

      selectedPhotos = [...photoArray.slice(firstPhotoIndex), ...missingItems]
    } else if (lastPhotoIndex > photoArray.length) {
      const missingItems = photoArray.slice(
        0,
        lastPhotoIndex - photoArray.length,
      )

      selectedPhotos = [...selectedPhotos, ...missingItems]
    } else {
      throw new Error('Case not handled')
    }
  }

  return selectedPhotos.map((photo, index) => {
    const photoIndex = 0 - (selectedCurrentIndex - index)

    const isCurrent = index === selectedCurrentIndex

    const relativeIndex = Math.abs(index - selectedCurrentIndex)

    const handleClick =
            photoIndex < 0 ? prevImage : photoIndex > 0 ? nextImage : undefined

    return (
      <PhotoWrapper
        key={photo._id}
        index={photoIndex}
        isCurrent={isCurrent}
        relativeIndex={relativeIndex}
        onClick={handleClick}
      >
        {photo.photo.asset.fluidLarge && (
          <StyledImage
            alt={photo.photoTitle}
            fluidAsset={photo.photo.asset.fluidLarge}
            aspectRatio={photo.photo.asset.fluidLarge.aspectRatio}
          />
        )}
      </PhotoWrapper>
    )
  })
}
