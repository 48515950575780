import React from 'react'
import styled from 'styled-components'

import addToCartIconWhite from '../../../static/svg/cart-add-white.svg'

import { IProduct } from '../../@types'
import { updateCart } from '../../functions/updateCart'
import { PrimaryButton } from '../../globalStyles'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { rhythm } from '../../utils/typography'
import { CartUpdates, mediaQueries } from '../../vars'

interface IProps {
  product: IProduct
  buttonText?: string
  onSuccess?: () => void
}

export function AddToCartButton({
  product,
  buttonText = 'Add to cart',
  onSuccess,
}: IProps) {
  const isDesktop = useMediaQuery(mediaQueries.general.desktop)

  function addToCart(): void {
    updateCart(product, CartUpdates.ADD)

    if (onSuccess) {
      onSuccess()
    }
  }

  return (
    <Button onClick={addToCart} value={buttonText} title='Add to cart'>
      {isDesktop && <span>{buttonText}</span>}
      <img src={addToCartIconWhite} alt='Add to cart icon' />
    </Button>
  )
}

const Button = styled(PrimaryButton)`
  @media ${mediaQueries.general.tablet} {
    padding: ${rhythm(1 / 4)};

    img {
      margin: 0;
      min-height: 36px;
    }
  }
`
