import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { TransitionStatus } from 'react-transition-group/Transition'
import { ISanityPhoto, TTogglePhotoPopup } from '../../../@types'
import { AddToCart } from './AddToCart'
import { Gallery } from './Gallery'
import { animationDuration } from './styles'

const windowGlobal: Window | false = typeof window !== 'undefined' && window

interface IProps {
  photoIndex: number
  photoArray: ISanityPhoto[]
  togglePhotoPopup: TTogglePhotoPopup
  addToCartInitial: boolean
}

export function PhotoPopup({
  photoArray,
  photoIndex,
  addToCartInitial,
  togglePhotoPopup,
}: IProps) {
  if (!windowGlobal) {
    return null
  }

  const [addToCart, setAddToCart] = useState(addToCartInitial)
  const [gallery, setGallery] = useState(!addToCart)
  const [currentPhoto, setCurrentPhoto] = useState(photoArray[photoIndex])

  const indexRef = useRef(photoIndex)

  function toggleAddingToCart() {
    if (addToCart) {
      setAddToCart(false)
    } else {
      setGallery(false)
    }
  }

  function handleAddToCartExit() {
    setGallery(true)
  }

  function handleGalleryExit() {
    setAddToCart(true)
  }

  function handleAddToCartClick(currentIndex: number) {
    toggleAddingToCart()

    setCurrentPhoto(photoArray[currentIndex])

    indexRef.current = currentIndex
  }

  function handleKeyPress({ key }: KeyboardEvent) {
    if (key === 'Escape') {
      togglePhotoPopup(indexRef.current, false)
    }
  }

  function callTogglePhotoPopup() {
    togglePhotoPopup(indexRef.current, false)
  }

  useEffect(() => {
    windowGlobal.addEventListener('keydown', handleKeyPress)

    return () => {
      windowGlobal.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  const closeFunction =
    (addToCart && addToCartInitial) || !addToCart
      ? callTogglePhotoPopup
      : toggleAddingToCart

  return (
    <TransitionGroup component={null}>
      {addToCart && (
        <CSSTransition
          timeout={animationDuration}
          key='addToCart'
          onExited={handleAddToCartExit}
        >
          {(state: TransitionStatus) => (
            <AddToCart
              transitionState={state}
              currentPhoto={currentPhoto}
              closeFunction={closeFunction}
            />
          )}
        </CSSTransition>
      )}
      {gallery && (
        <CSSTransition
          timeout={animationDuration}
          key='gallery'
          onExited={handleGalleryExit}
        >
          {(state: TransitionStatus) => (
            <Gallery
              transitionState={state}
              closeFunction={closeFunction}
              photoArray={photoArray}
              photoIndex={indexRef.current}
              handleAddToCartClick={handleAddToCartClick}
            />
          )}
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
