import React from 'react'

import helpIcon from '../../../../../../static/svg/help.svg'

import { Container, Tooltip } from './styles'
import { graphql, useStaticQuery } from 'gatsby'

interface IQueryResult {
  allSanityMaterial: {
    nodes: Array<{
      name: string
      description: string
    }>
  }
}

export function MaterialLegend() {
  const { allSanityMaterial }: IQueryResult = useStaticQuery(graphql`
      {
          allSanityMaterial {
              nodes {
                  name
                  description
              }
          }
      }
  `)

  return (
    <Container>
      Choose a material
      <Tooltip>
        <img src={helpIcon} alt='Help icon' />
        <span>
          Prints are available in:{' '}
          <ul>
            {allSanityMaterial.nodes.map(({ name, description }) => {
              return (
                <li key={name}>
                  <span>{name}:</span> {description}
                </li>
              )
            })}
          </ul>
        </span>
      </Tooltip>
    </Container>
  )
}
