import React from 'react'
import { tRadioChangeHandler } from '../../../classes/RadioElements'
import { RadioInput } from '../RadioInput'

interface IProps {
  changeHandler: tRadioChangeHandler
  variantTitle: string
}

interface IMaterialProps extends IProps {
  materialName: string
  selectedMaterial: string
}

export function MaterialRadio({
  materialName,
  changeHandler,
  selectedMaterial,
  variantTitle,
}: IMaterialProps) {
  function handleChange() {
    changeHandler(variantTitle)
  }

  return (
    <RadioInput
      id={materialName}
      selectedId={selectedMaterial}
      changeHandler={handleChange}
      radioGroup='materials'
    >
      {materialName}
    </RadioInput>
  )
}

interface ISizeProps extends IProps {
  size: string
  selectedSize: string
}

export function SizeRadio({
  variantTitle,
  size,
  changeHandler,
  selectedSize,
}: ISizeProps) {
  function handleChange() {
    changeHandler(variantTitle)
  }

  return (
    <RadioInput
      id={size}
      selectedId={selectedSize}
      changeHandler={handleChange}
      radioGroup='sizes'
    >
      {size}
    </RadioInput>
  )
}
