import styled, { css, keyframes } from 'styled-components'

import { cardEnterAnim } from '../../globalStyles'
import { rhythm } from '../../utils/typography'
import { mediaQueries } from '../../vars'

export const Header = styled.header`
  margin: ${rhythm(1 / 2)};
  max-width: 70%;

  h1 span {
    color: #888;
    margin-left: ${rhythm(1 / 3)};
    display: inline-flex;
  }

  h1 img {
    margin: 0 0 -2px ${rhythm(1 / 3)};
    height: ${rhythm(1.3)};
  }
`

export const Container = styled.div`
  background: #ffffffdd;
  max-width: 1300px;
  border-radius: 10px;
  margin: 0 auto;
  animation: ${cardEnterAnim} 0.6s 0.2s backwards;
  display: flex;
  flex-direction: column;

  @media ${mediaQueries.general.desktop}, ${mediaQueries.portrait.tablet} {
    padding: ${rhythm(2)};
  }

  @media (min-width: 1500px) and (orientation: landscape) {
    padding: ${rhythm(2)};
    box-shadow: var(--box-shadow);
  }
`

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`

interface ISearchFocused {
  searchFocused: boolean
}

export const SearchForm = styled.form<ISearchFocused>`
  margin-right: ${rhythm(1)};
  margin-top: ${rhythm(1 / 2)};
  position: relative;
  transition: all 0.5s;
  width: 50px;
  height: 50px;
  background: var(--white);
  box-shadow: var(--box-shadow);
  box-sizing: border-box;
  border-radius: 25px;
  border: 4px solid white;
  padding: 5px;

  ${({ searchFocused }) => {
  return (
    searchFocused &&
    css`
        width: 200px;
        cursor: pointer;
      `
  )
}}

  :hover,
  :focus {
    width: 200px;
    cursor: pointer;

    input::placeholder {
      color: initial;
    }
  }

  @media ${mediaQueries.general.handheld} {
    position: absolute;
    top: ${rhythm(1 / 5)};
    right: ${rhythm(0)};
  }
`

export const SearchInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42.5px;
  line-height: 30px;
  outline: 0;
  border: 0;
  display: block;
  font-size: 1em;
  border-radius: 20px;
  padding: 0 20px;

  ::placeholder {
    color: transparent;
  }
`

export const SearchIcon = styled.img`
  box-sizing: border-box;
  padding: 10px;
  width: 42.5px;
  height: 42.5px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  color: #07051a;
  text-align: center;
  font-size: 1.2em;
`

export const Images = styled.div`
  margin: ${rhythm(1 / 2)};
  column-count: 10;
  column-gap: ${rhythm(1 / 2)};

  @media ${mediaQueries.portrait.handheld} {
    column-width: 40vw;
  }

  @media ${mediaQueries.landscape.handheld} {
    column-width: 30vw;
  }

  @media ${mediaQueries.portrait.tablet}, ${mediaQueries.landscape.tablet} {
    column-width: 20vw;
  }

  @media ${mediaQueries.general.desktop} {
    column-width: 15vw;
  }
`

const animation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const animationDuration = 400

export const PopupWrapper = styled.div`
  &.enter,
  &.exit {
    animation: ${animation} ${animationDuration}ms both;
  }

  &.exit {
    animation-direction: reverse;
  }
`
