import styled from 'styled-components'

import { Legend } from '../styles'
import { rhythm } from '../../../../../utils/typography'

export const Container = styled(Legend)`
  display: flex;
  align-items: center;
`

export const Tooltip = styled.div`
  --background: #fff;

  position: relative;
  display: inline-block;
  height: 1rem;

  img {
    margin-bottom: 0;
    margin-left: 3px;
    height: 1rem;
  }

  & > span {
    color: var(--black);
    background: var(--background);
    padding: ${rhythm(1)};
    border-radius: 10px;
    border: 1px solid var(--primary);
    box-shadow: var(--box-shadow-light);
    position: absolute;
    z-index: 1;
    visibility: hidden;
    width: 300px;
    top: 98%;
    left: 50%;
    margin-left: -153px;
    opacity: 0;
    transition: 0.5s opacity;
  }
  
  ul {
    margin: ${rhythm(.4)} 0 0;
    list-style: none;
    
    span {
      display: block;
      font-weight: bold;
    }
  }

  :hover span {
    visibility: visible;
    opacity: 1;
  }
`
