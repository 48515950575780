import { IRawVariant, IVariant } from '../@types'

interface IVariantsObject {
  [variantTitle: string]: IVariant
}

export class Variants {
  public variants: IVariant[]
  public variantsObject: IVariantsObject

  constructor(variants: IRawVariant[]) {
    function prettifyVariants(rawVariants: IRawVariant[]): IVariant[] {
      return rawVariants.map((rawVariant: IRawVariant) => {
        const {
                _id,
                variantTitle: { name: variantTitle },
                price           : { amount: price },
                size        : { dimensions: size },
                material    : { name: material },
                dimensions  : { height, length, weight, width },
              } = rawVariant

        return {
          _id,
          quantity  : 0,
          variantTitle,
          price,
          size,
          material,
          dimensions: { width, weight, length, height },
        }
      })
    }

    function getVariantsObject(variantsArray: IVariant[]): IVariantsObject {
      return variantsArray.reduce(
        (accumulatedObject: IVariantsObject, curVariant: IVariant) => {
          accumulatedObject[curVariant.variantTitle] = curVariant

          return accumulatedObject
        },
        {},
      )
    }

    this.variants       = prettifyVariants(variants)
    this.variantsObject = getVariantsObject(this.variants)
  }
}
