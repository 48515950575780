import { cart, IProduct } from '../@types'
import { CartUpdates, storageKey } from '../vars'

const windowGlobal: Window | false = typeof window !== 'undefined' && window

type tPossibleCart = cart | null

export const updateCart = (
  product: IProduct,
  updateType: CartUpdates,
  quantity?: number,
): void => {
  if (!windowGlobal || !windowGlobal.localStorage) {
    return
  }

  const rawCart: string | null = windowGlobal.localStorage.getItem(storageKey)
  const oldCart: tPossibleCart = rawCart && JSON.parse(rawCart)

  switch (updateType) {
    case CartUpdates.ADD:
      add(oldCart, product)
      break
    case CartUpdates.REMOVE:
      if (oldCart) {
        remove(oldCart, product)
      }
      break
    case CartUpdates.QUANTITY:
      if (quantity && oldCart) {
        updateQuantity(oldCart, product, quantity)
      }
      break

    default:
      break
  }
}

function add(oldCart: tPossibleCart, product: IProduct) {
  if (!windowGlobal) {
    return
  }

  if (!oldCart) {
    windowGlobal.localStorage.setItem(storageKey, JSON.stringify([product]))

    return
  }

  let duplicateItem = false

  oldCart.forEach((oldProduct: IProduct) => {
    const oldItemId = oldProduct._id + oldProduct.selectedVariant._id
    const productId = product._id + product.selectedVariant._id
    const same      = oldItemId === productId
    if (same) {
      oldProduct.quantity += product.quantity
      duplicateItem = true
    }
  })

  const newCart: cart = duplicateItem ? oldCart : [...oldCart, product]

  windowGlobal.localStorage.setItem(storageKey, JSON.stringify(newCart))
}

function remove(oldCart: cart, product: IProduct) {
  if (!windowGlobal) {
    return
  }

  const newCart = oldCart.filter((oldItem: IProduct) => {
    const oldItemId = oldItem._id + oldItem.selectedVariant._id
    const productId = product._id + product.selectedVariant._id

    return oldItemId !== productId
  })

  windowGlobal.localStorage.setItem(storageKey, JSON.stringify(newCart))
}

function updateQuantity(
  oldCart: cart,
  product: IProduct,
  quantity: number,
) {
  if (!windowGlobal) {
    return
  }

  const newCart: cart = oldCart.map((oldProduct: IProduct) => {
    const oldItemId = oldProduct._id + oldProduct.selectedVariant._id
    const productId = product._id + product.selectedVariant._id

    const same = oldItemId === productId

    if (same) {
      oldProduct.quantity = quantity
    }

    return oldProduct
  })

  windowGlobal.localStorage.setItem(storageKey, JSON.stringify(newCart))
}
