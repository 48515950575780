import { graphql, useStaticQuery } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import React from 'react'

import { IBlockText } from '../../../@types'
import { Details } from './styles'

interface IQueryResult {
  sanitySiteCopy: {
    _rawCustomRequestsText: IBlockText[]
    customRequestsAvailable: boolean
  }
}

export function CustomPrintNotification() {
  const { sanitySiteCopy }: IQueryResult = useStaticQuery(graphql`
      {
          sanitySiteCopy {
              _rawCustomRequestsText 
              customRequestsAvailable
          }
      }
  `)

  return sanitySiteCopy.customRequestsAvailable ? (
    <Details>
      <summary>Can't find what you're looking for?</summary>
      <BlockContent blocks={sanitySiteCopy._rawCustomRequestsText} />
    </Details>
  ) : null
}