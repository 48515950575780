import styled from 'styled-components'

import { IconButton } from '../../../../globalStyles'
import { rhythm } from '../../../../utils/typography'
import { mediaQueries } from '../../../../vars'
import { Image } from '../../../common/Image'
import { popupContainerStyles } from '../styles'

export const Container = styled.div`
  ${popupContainerStyles};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
`

export const Photos = styled.div`
  position: relative;
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  perspective: 2000px;
  transform-style: flat;
`

interface IPhotoWrapper {
  index: number
  isCurrent: boolean
  relativeIndex: number
}

export const PhotoWrapper = styled.div<IPhotoWrapper>`
  max-width: 720px;
  position: absolute;
  transform: ${({ index, relativeIndex, isCurrent }) =>
    `translate3d(calc(100% * ${index}), 0, ${isCurrent ? 100 : 0}px) scale(${1 -
      relativeIndex * 0.15}) rotate3d(0, 1, 0, ${-45 * index}deg)`};
  z-index: ${({ isCurrent }) => (isCurrent ? 100 : 0)};
  opacity: ${({ isCurrent, relativeIndex }) =>
    isCurrent ? 1 : relativeIndex > 1 ? 0 : 0.7};
  transition: all 0.7s;
`

interface IAspectRatio {
  aspectRatio: number
}

export const StyledImage = styled(Image)<IAspectRatio>`
  margin: 0 auto;
  box-shadow: var(--box-shadow-heavy);
  width: ${({ aspectRatio }) =>
    aspectRatio ? `calc(var(--max-height) * ${aspectRatio})` : '90vw'};
  max-height: var(--max-height);
  border-radius: 5px;

  @media ${mediaQueries.general.tablet} {
    --max-height: 35vh;
    max-width: 90vw;
  }

  @media ${mediaQueries.general.desktop} {
    max-width: 720px;
    --max-height: 50vh;
  }
`

export const SupportingContent = styled.div`
  width: 100%;

  @media ${mediaQueries.general.tablet} and (orientation: portrait) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
  }

  @media (orientation: landscape) {
    max-width: 80%;
  }

  @media ${mediaQueries.general.tablet} and (orientation: landscape) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    max-height: 30vh;
    margin: ${rhythm(1)} 0;
  }

  @media ${mediaQueries.general.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${rhythm(1)};
    width: 70vw;
    max-height: 25vh;
  }
`

export const ButtonDiv = styled.div`
  width: 90%;

  @media ${mediaQueries.general.desktop} {
    max-width: 50%;
    display: flex;
    justify-content: space-between;
  }

  @media ${mediaQueries.general.tablet} and (orientation: landscape) {
    display: flex;
    justify-content: space-around;
    width: 60%;
  }
`

export const Description = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  h1 {
    font-size: 1.1rem;
    margin: 0;
    line-height: 1.5rem;
  }

  p {
    margin: 0;
    color: #555;
  }

  @media ${mediaQueries.general.desktop} {
    margin: ${rhythm(1)} 0 ${rhythm(1)};
    max-width: 50%;
    max-height: 15vh;

    h1 {
      font-size: 1.4rem;
      line-height: initial;
    }

    p {
      font-size: 1.1rem;
    }
  }
`

export const Controls = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: ${rhythm(1)} 0;

  @media ${mediaQueries.general.tablet} and (orientation: landscape) {
    margin: 0;
  }

  @media ${mediaQueries.general.desktop} {
    width: 50%;
  }
`

export const Buttons = styled(Controls)`
  justify-content: space-around;

  @media ${mediaQueries.general.tablet} and (orientation: landscape) {
    margin: 0;
  }

  @media ${mediaQueries.general.desktop} {
    margin-top: ${rhythm(1)};
    width: 15%;
  }
`

interface IIntervalFraction {
  intervalFraction: number
}

export const Timer = styled.div.attrs(
  ({ intervalFraction }: IIntervalFraction) => ({
    style: {
      width: intervalFraction ? `calc(100vw * ${intervalFraction})` : 0,
    },
  })
)<IIntervalFraction>`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--primary);
  height: 3px;
`

export const Button = styled(IconButton)`
  transition: transform 0.3s;

  :hover {
    transform: scale(1.2);
  }
`

export const CloseButton = styled(Button)`
  @media ${mediaQueries.general.desktop} {
    position: absolute;
    top: ${rhythm(1)};
    right: ${rhythm(1)};
  }
`
