import { graphql } from 'gatsby'
import React from 'react'

import imageIcon from '../../static/svg/image.svg'

import { ISanityPhoto } from '../@types'
import { PhotosPage } from '../components/PhotosPage'

interface IProps {
  data: {
    allSanityPhoto: {
      nodes: ISanityPhoto[]
    }
  }
  pageContext: {
    pageTitle: string
    iconURL: string
  }
}

export default function index({ data, pageContext }: IProps) {
  const iconURL = pageContext.iconURL ? pageContext.iconURL : imageIcon

  return (
    <PhotosPage
      photoArray={data.allSanityPhoto.nodes}
      pageTitle={pageContext.pageTitle}
      iconURL={iconURL}
    />
  )
}

export const query = graphql`
  query Photos($ids: [String]) {
    allSanityPhoto(filter: { _id: { in: $ids }, forSale: { eq: true } }) {
      nodes {
        photo {
          asset {
            fluidLarge: fluid(maxWidth: 720) {
              ...GatsbySanityImageFluid_noBase64
            }
            fluidSmall: fluid(maxWidth: 720) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        keywords
        photoTitle
        desc
        _rawCategories(resolveReferences: { maxDepth: 10 })
        _rawVariants(resolveReferences: { maxDepth: 10 })
        _id
      }
    }
  }
`
