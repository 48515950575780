import styled from 'styled-components'

import { IconButton } from '../../../globalStyles'
import { rhythm } from '../../../utils/typography'
import { Image } from '../../common/Image'
import { mediaQueries } from '../../../vars'

export const ColumnItemProtector = styled.div`
  border: 5px solid transparent;
  break-inside: avoid;
  backface-visibility: hidden;
`

export const Container = styled.div`
  --scale-inactive: 1;
  --scale-active: 1.03;

  margin-bottom: ${rhythm(1 / 2)};
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: scale(var(--scale-inactive));
  box-shadow: var(--box-shadow);
  backface-visibility: hidden; /* Important! */
  border-radius: 5px;
  background: transparent;

  @media (hover: hover) {
    :hover {
      transform: scale(var(--scale-active));
      box-shadow: var(--box-shadow-heavy);
    }
  }

  // Safari bug fix
  @media ${mediaQueries.general.desktop} {
    @media not all and (min-resolution: 0.001dpcm) {
      @media {
        box-shadow: unset;

        :hover {
          box-shadow: unset;
        }
      }
    }
  }
`

export const StyledImage = styled(Image)`
  margin: 0;
  border-radius: 5px;
  vertical-align: middle;
  max-width: 720px;
`

export const OverlayButton = styled(IconButton)`
  --opacity-inactive: 0.7;
  --width: 36px;
  backface-visibility: hidden;
  width: var(--width);
  position: absolute;
  background-color: #ffffff;
  border-radius: 5px;
  bottom: ${rhythm(1 / 3)};
  opacity: 0;
  transition: all 0.3s;
  z-index: 2;

  &:hover {
    opacity: 1 !important;
    transform: scale(1.05);

    img {
      transform: none;
    }
  }

  &:focus {
    opacity: 1;
    transform: scale(1.05);

    img {
      transform: none;
    }
  }
  ${Container}:hover & {
    opacity: var(--opacity-inactive);
  }
`

export const AddToCartOverlayBtn = styled(OverlayButton)`
  right: ${rhythm(1 / 3)};
`

export const MaximiseOverlayBtn = styled(OverlayButton)`
  right: calc(var(--width) + ${rhythm(3 / 4)});
`

export const Icon = styled.img`
  margin: 0;
  max-width: var(--width);
`
